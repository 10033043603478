import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {
	mapActions
} from 'vuex'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				domainNames: [],
				date: [
					Moment().startOf('day').add(-6, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(0, 'day').format('YYYY-MM-DD')
				]
			},
			tableData: [],
			q: {
				dateFrom: Moment().startOf('day').add(-6, 'day').format('YYYY-MM-DD'),
				dateTo: Moment().startOf('day').add(0, 'day').format('YYYY-MM-DD')
			},
			namespace: '',
			fetchOnCreated: false
		}
	},
	created() {
		const {
			dateFrom,
			dateTo,
			domainNames
		} = this.$route.query
		if (dateFrom && dateTo && /\d{4}-\d{2}-\d{2}/.test(dateFrom) && /\d{4}-\d{2}-\d{2}/.test(dateTo)) {
			this.queryForm.date = [dateFrom, dateTo]
			this.q.dateFrom = dateFrom
			this.q.dateTo = dateTo
		}
		if (domainNames !== undefined && domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
			this.queryForm.domainNames = domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g)
		}
		this.searchEvent(false)
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
					if (key === 'date') {
						const [dateFrom, dateTo] = queryForm[key]
						query['dateFrom'] = dateFrom
						query['dateTo'] = dateTo
					} 
					else if (key === 'domainNames') {
						query['domainNames'] = queryForm['domainNames'].reduce((lhs, rhs) => {
							if (rhs.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
								lhs.push(rhs)
							}
							return lhs
						}, [])
					}
				}
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent(showErr=true) {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		getList() {
			this.showLoading()
			this.getListUrl()
				.then(data => {
					this.hideLoading()
					this.handleList(data)
				})
				.catch(err => {
					this.hideLoading()
				})
			this.trigger = true
		},
		handleList(content) {
			this.tableData = content
			this.doLayout()
		},
		doLayout() {},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			return this.$store.dispatch(`${this.namespace}/getEstWatch`, {
				params: {
					...this.q
				},
				trigger
			})
		}
	}
}