<template>
	<el-table class="jscx-est-watch-table" height="auto" ref="multipleTable" :data="tableData" @select="pinSelect"
		@sort-change="$emit('sortChange', $event)" size="mini" border :default-sort = "{prop: 'epc', order: 'descending'}">
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			min-width="180" align="center" :sortable="tableColumn.sortable" :formatter="cellFormatter"
			:sort-method="getSortMethod(tableColumn.prop)">
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: () => []
			},
			tableColumns: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			cellFormatter(row, column, cellValue, index) {
				if (/epc/i.test(column.property)) {
					const epc = Number(row.estimatedRevenue) / Number(row.clicks)
					const formatted = (isFinite(epc) ? Number(epc).toFixed(3) : '0.000')
					return '$' + formatted.substring(0, formatted.length - 1)
				}
				else if (/ctr/i.test(column.property)) {
					return (Number(cellValue)*100).toFixed(2) + '%'
				}
				else if (/(clicks)|(visits)/i.test(column.property)) {
					return Number(cellValue)
				}
				else if (/(updatedAt)|(serverDatetime)|(costDate)|(createdAt)/i.test(column.property) && cellValue) {
					return Moment(Number(cellValue)).utc().format('YYYY-MM-DDTHH:mm:ssZ')
				}
				else if (/site/i.test(column.property) && row.refPubsite) {
					try {
						const sub_string = row.refPubsite.split('-')[1]
						if (/^SASITE/.test(sub_string)) {
							return sub_string.slice(6)
						}
					} catch (e) {console.info(e)}
				}
				return cellValue || '-'
			},
			// 根据 prop 动态返回排序方法
			getSortMethod(prop) {
				if (prop === 'epc') {
					return this.sortEpc
				} 
				else if (prop === 'clicks' ) {
					return this.sortClicks
				}
				else if (prop === 'visits') {
					return this.sortVisits
				}
				else if (prop === 'ctr') {
					return this.sortCtr
				}
				return null; // 对于不需要自定义排序的列，返回 null 或 undefined
			},
			sortClicks(a, b) {
				return Number(a.clicks) - Number(b.clicks)
			},
			sortVisits(a, b) {
				return Number(a.visits) - Number(b.visits)
			},
			sortCtr(a, b) {
				return Number(a.ctr) - Number(b.ctr)
			},
			sortEpc(a, b) {
				const numA = Number(a.clicks) === 0 ? 0 : Number(a.estimatedRevenue) / Number(a.clicks)
				const numB = Number(b.clicks) === 0 ? 0 : Number(b.estimatedRevenue) / Number(b.clicks)
				return numA - numB;
			}
		}
	}
</script>

<style>
	.jscx-est-watch-table.el-table .el-table__body tr:hover > td.el-table__cell {
		background-color: unset;
	}
	.jscx-est-watch-table.el-table th.el-table__cell > .cell {
		text-transform: capitalize;
	}
</style>