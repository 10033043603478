<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false" :picker-options="pickerOptions">
					</el-date-picker>
				</form-item>
				<form-item label="Domain Names">
					<el-select v-model="queryForm.domainNames" filterable placeholder="请选择" size="mini" clearable multiple>
						<el-option v-for="item in sortedDomainNames" :key="item.domainName" :label="item.domainName" :value="item.domainName">
						</el-option>
					</el-select>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" @click="showEdit" size="mini">显示编辑</el-button>
				</form-item>
			</template>
			<template #advanced v-if="false">
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<est-watch-table ref="dataTable" class="mt-3 w-100" :tableData="tableData" :tableColumns="filterTableColumns"></est-watch-table>
		<el-drawer title="显示编辑" :visible.sync="drawer.edit" direction="rtl">
			<div style="padding: 0 20px;">
				<el-tree class="jscx-est-watch-tree" ref="col-tree" draggable :data="sortedTableColumns" show-checkbox
					node-key="prop" :allowDrop="allowDrop" :default-checked-keys="checkedKeys"
					@check-change="checkChange" @node-drop="handleDrop">
				</el-tree>
			</div>
		</el-drawer>
	</el-card>
</template>

<script>
	import EstWatchTable from '@/components/jscx/est-watch-table.vue'
	import Moment from 'moment'
	import config from './minixs/est-watch.js'
	export default {
		components: {
			EstWatchTable
		},
		mixins: [config],
		data() {
			return {
				namespace: 'jscx',
				pickerOptions: {
					disabledDate(time) {
						const today = new Date();
						const sixtyDaysAgo = today.getTime() - 60 * 24 * 60 * 60 * 1000; // 60 天前的时间戳
						return time.getTime() < sixtyDaysAgo || time.getTime() > today; // 禁用不在范围内的日期
					}
				},
				drawer: {
					edit: false
				},
				sortedKeys: [],
				checkedKeys: [],
				resources: {
					domainNames: []
				},  
				tableColumns: [
					{
						label: "public Id",
						prop: "publicId",
						sortable: false
					},
					{
						label: "domain Name",
						prop: "domainName",
						disabled: true,
						sortable: false
					},
					{
						label: "subid_3",
						prop: "subid3",
						sortable: false
					},
					{
						label: "ref Pubsite",
						prop: "refPubsite",
						sortable: false
					},
					{
						label: "site",
						prop: "site",
						sortable: false
					},
					{
						label: "insert time",
						prop: "createdAt",
						sortable: false
					},
					{
						label: "change time",
						prop: "updatedAt",
						sortable: false
					},
					// {
					// 	label: "campaign Id",
					// 	prop: "campaignId",
					// 	sortable: false
					// },
					// {
					// 	label: "adset Id",
					// 	prop: "adsetId",
					// 	sortable: false
					// },
					// {
					// 	label: "ad Id",
					// 	prop: "adId",
					// 	sortable: false
					// },
					// {
					// 	label: "cost Date",
					// 	prop: "costDate",
					// 	sortable: false
					// },
					// {
					// 	label: "visits",
					// 	prop: "visits",
					// 	sortable: true
					// },
					// {
					// 	label: "clicks",
					// 	prop: "clicks",
					// 	sortable: true
					// },
					// {
					// 	label: "ctr",
					// 	prop: "ctr",
					// 	sortable: true
					// },
					// {
					// 	label: "epc",
					// 	prop: "epc",
					// 	sortable: true
					// },
					// {
					// 	label: "estimated Revenue",
					// 	prop: "estimatedRevenue",
					// 	sortable: true
					// },
					
					// {
					// 	label: "ref Keyword",
					// 	prop: "refKeyword",
					// 	sortable: false
					// },
					// {
					// 	label: "server Datetime",
					// 	prop: "serverDatetime",
					// 	sortable: false
					// },
					// {
					// 	label: "subid_1",
					// 	prop: "subid1",
					// 	sortable: false
					// },
					// {
					// 	label: "subid_2",
					// 	prop: "subid2",
					// 	sortable: false
					// },
					
					// {
					// 	label: "subid_4",
					// 	prop: "subid4",
					// 	sortable: false
					// },
					// {
					// 	label: "subids",
					// 	prop: "subids",
					// 	sortable: false
					// },
					// {
					// 	label: "userName",
					// 	prop: "userName",
					// 	sortable: true
					// }
				]
			}
		},
		created() {
			const checkedKeys = localStorage.getItem(`${this.namespace}_est_watch_${process.env.VUE_APP_VERSION}_checked_keys`)
			if (checkedKeys) {
				try {
					this.checkedKeys = JSON.parse(checkedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.checkedKeys = this.tableColumns.map(v => v.prop)
			}
			const sortedKeys = localStorage.getItem(`${this.namespace}_est_watch_${process.env.VUE_APP_VERSION}_sorted_keys`)
			if (sortedKeys) {
				try {
					this.sortedKeys = JSON.parse(sortedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.sortedKeys = this.tableColumns.map(v => v.prop)
			}
		},
		mounted() {
			this.initResouces()
		},
		computed: {
			sortedTableColumns() {
				const defaultSortColumns = this.tableColumns.map(v => v.prop)
				return this.tableColumns.reduce((lhs, rhs) => {
					const index = this.sortedKeys.indexOf(rhs.prop)
					if (index !== -1) {
						lhs[index] = rhs
					}
					return lhs
				}, new Array(this.tableColumns.length))
			},
			filterTableColumns() {
				return this.sortedTableColumns.reduce((lhs, rhs) => {
					if (this.checkedKeys.indexOf(rhs.prop) !== -1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			sortedDomainNames() {
				return this.resources.domainNames.sort((a, b) => a.domainName.localeCompare(b.domainName))
			}
		},
		methods: {
			initResouces() {
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (action, resourceKey, retries) => {
					this.$store.dispatch(`${this.namespace}/${action}`)
						.then(res => {
							this.resources[resourceKey] = res; // 成功时设置资源
						})
						.catch(() => {
							if (retries > 0) {
								console.warn(`重试 ${resourceKey}，剩余重试次数: ${retries}`);
								fetchWithRetry(action, resourceKey, retries - 1); // 失败时递归重试
							} else {
								console.error(`${resourceKey} 获取失败，已达到最大重试次数`);
							}
						});
				};
				// 调用重试方法获取资源
				fetchWithRetry('getDomains', 'domainNames', maxRetries);
			},
			showEdit() {
				this.drawer.edit = true
			},
			allowDrop(draggingNode, dropNode, type) {
				return type !== 'inner'
			},
			checkChange(data, checked, indeterminate) {
				this.checkedKeys = this.$refs['col-tree'].getCheckedKeys()
				localStorage.setItem(`${this.namespace}_est_watch_${process.env.VUE_APP_VERSION}_checked_keys`, JSON.stringify(this
					.checkedKeys))
			},
			handleDrop(draggingNode, dropNode, dropType, ev) {
				this.sortedKeys = dropNode.parent.childNodes.map(v => v.data.prop)
				localStorage.setItem(`${this.namespace}_est_watch_${process.env.VUE_APP_VERSION}_sorted_keys`, JSON.stringify(this
					.sortedKeys))
			}
		}
	}
</script>

<style>
	.jscx-est-watch-tree .el-tree-node__content .el-tree-node__label {
		text-transform: capitalize;
	}
</style>